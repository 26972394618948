import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import S from "./styles";
import { ContentfulPageHeader } from "../../../interfaces";
import Container from "../../common/Container";

export const fragment = graphql`
  fragment Header on ContentfulHeader {
    id
    internal {
      type
    }
    heading
    image {
      title
      fluid(quality: 80, maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

interface PageHeaderProps extends ContentfulPageHeader {}

const PageHeader = ({ heading, image }: PageHeaderProps) => {
  return (
    <S.PageHeader>
      {image && (
        <Img
          fadeIn={false}
          alt={image.title}
          fluid={image.fluid}
          style={{ position: "absolute", width: "100%", height: "100%" }}
        />
      )}
      <Container>
        {heading && <h1>{heading}</h1>}
        <div className="scroll-text">scroll</div>
      </Container>
    </S.PageHeader>
  );
};

export default PageHeader;
