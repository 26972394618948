import React, { useRef, useEffect, useContext } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";
import Img from "gatsby-image";
import { HeaderContext } from "../../../context/HeaderContext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulRichTextLinks, getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import {
  desktopPanelAnimation,
  mobilePanelAnimation,
  imageExpandAnimation,
} from "./animations";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const Panel = ({ panel, pageMap }) => {
  const { setHeaderVisible } = useContext(HeaderContext);
  const animatedImageRef = useRef(null);
  const panelRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 750) {
      mobilePanelAnimation(panelRef);
    } else {
      desktopPanelAnimation(panelRef);
    }
  }, []);

  return (
    <S.Panel key={panel.id} className={`panel`} ref={panelRef}>
      {panel.image && (
        <div className="image">
          {panel.link && panel.animateImage ? (
            <TransitionLink
              to={getSlugByID({
                pageMap,
                pageID: panel.link.contentful_id,
              })}
              exit={{
                trigger: ({ exit, node }) => {
                  setHeaderVisible(false);
                  imageExpandAnimation(animatedImageRef);
                },
                length: 1,
              }}
              entry={{
                delay: 1,
              }}
            >
              <Img
                className="original-image"
                alt={panel.image.title}
                fluid={panel.image.fluid}
                style={{
                  flex: 1,
                }}
              />
              <div className="image-clone" ref={animatedImageRef}>
                <Img alt={panel.image.title} fluid={panel.image.fluid} />
              </div>
            </TransitionLink>
          ) : (
            <Img
              className="original-image"
              alt={panel.image.title}
              fluid={panel.image.fluid}
              style={{
                flex: 1,
              }}
            />
          )}
        </div>
      )}
      <div className="panel-content">
        <div className="content">
          {panel.subtitle && <h4>{panel.subtitle}</h4>}
          {panel.link && panel.title ? (
            <TransitionLink
              to={getSlugByID({
                pageMap,
                pageID: panel.link.contentful_id,
              })}
              exit={{
                trigger: ({ exit, node }) => {
                  setHeaderVisible(false);
                  imageExpandAnimation(animatedImageRef);
                },
                length: 1,
              }}
              entry={{
                delay: 1,
              }}
            >
              <h2>{panel.title}</h2>
            </TransitionLink>
          ) : (
            <h2>{panel.title}</h2>
          )}

          {panel.content &&
            documentToReactComponents(panel.content.json, {
              renderNode: {
                ...contentfulRichTextLinks({ pageMap }),
              },
            })}
          {panel.link &&
            panel.linkText &&
            (panel.animateImage ? (
              <TransitionLink
                to={getSlugByID({
                  pageMap,
                  pageID: panel.link.contentful_id,
                })}
                exit={{
                  trigger: ({ exit, node }) => {
                    setHeaderVisible(false);
                    imageExpandAnimation(animatedImageRef);
                  },
                  length: 1.5,
                }}
                entry={{
                  delay: 1.5,
                }}
              >
                {panel.linkText}
              </TransitionLink>
            ) : (
              <AniLink
                to={getSlugByID({
                  pageMap,
                  pageID: panel.link.contentful_id,
                })}
                cover
                bg="white"
                direction="right"
              >
                {panel.linkText}
              </AniLink>
            ))}
        </div>
      </div>
    </S.Panel>
  );
};

export default Panel;
