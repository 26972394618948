import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import { contentfulRichTextLinks } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulBasicTitleContent, PathContext } from "../../../interfaces";

export const fragment = graphql`
  fragment BasicTitleContent on ContentfulBasicTitleContent {
    id
    internal {
      type
    }
    title
    content {
      json
    }
    showLastUpdated
    updatedAt(formatString: "MMMM DD, YYYY")
  }
`;

interface BasicTitleContentProps extends ContentfulBasicTitleContent {
  pathContext: PathContext;
}

const BasicTitleContent = ({
  title,
  showLastUpdated,
  updatedAt,
  content: { json },
  pathContext: { pageMap },
}: BasicTitleContentProps) => {
  return (
    <S.BasicTitleContent>
      <Container>
        {title && <h2 className="section-title">{title}</h2>}
        {showLastUpdated && (
          <div className="updated">Last updated [{updatedAt}]</div>
        )}
        <div className="content">
          {documentToReactComponents(json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
            },
          })}
        </div>
      </Container>
    </S.BasicTitleContent>
  );
};

export default BasicTitleContent;
