import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import { contentfulRichTextLinks, contentfulRichTextInlineImage } from "../../../utils/helpers";
import S from "./styles";
import {
  ContentfulBasicContentAlternate,
  PathContext,
} from "../../../interfaces";

export const fragment = graphql`
  fragment BasicContentAlternate on ContentfulBasicContentAlternate {
    id
    internal {
      type
    }
    heading
    subheading
    content {
      json
    }
  }
`;

interface BasicContentAlternateProps extends ContentfulBasicContentAlternate {
  pathContext: PathContext;
}

const BasicContentAlternate = ({
  heading,
  subheading,
  content: { json },
  pathContext: { pageMap },
}: BasicContentAlternateProps) => {
  return (
    <S.BasicContentAlternate>
      <Container>
        <div className="content">
          {subheading && <h4>{subheading}</h4>}
          {heading && <h3>{heading}</h3>}

          {documentToReactComponents(json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
              ...contentfulRichTextInlineImage,
            },
          })}
        </div>
      </Container>
    </S.BasicContentAlternate>
  );
};

export default BasicContentAlternate;
