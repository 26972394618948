import styled from "styled-components";

const ServiceCategories = styled.section`
  position: relative;
  padding-bottom: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-bottom: 30px;
  }

  .category {
    &:first-of-type {
      margin-top: 0;
    }

    &:nth-of-type(even) {
      .content {
        margin-left: auto;
      }
      .image {
        order: 2;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          order: 1;
        }
      }
      .title {
        order: 1;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          order: 2;
        }
      }
      ul {
        margin-left: auto;
      }
    }
  }
`;

const Category = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: flex-start;
    margin-top: 0;
  }

  .image {
    position: relative;
    order: 1;
    width: 75%;
    margin-bottom: 60px;
    min-height: 600px;
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      height: 360px;
      display: flex;
      width: calc(100% + 40px);
      margin: 0 -20px;
      min-height: auto;
    }

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
      ${({ theme }) => theme.mixins.imageScale};
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        object-fit: cover;
        object-position: center;
        height: auto;
      }
    }
  }

  .title {
    position: relative;
    order: 2;
    width: 25%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      margin: 40px 0 60px 0;
      width: 20%;
      text-align: right;
    }

    h3 {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      text-orientation: mixed;
      margin: 0;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      font-weight: normal;
      font-size: 45px;
      line-height: 55px;
      letter-spacing: 0.6px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 28px;
        line-height: 55px;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    list-style: none;
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    letter-spacing: 0.3px;
    font-size: 20px;
    line-height: 28px;
    width: 75%;
    padding-left: 15%;
    padding-bottom: 75px;

    b {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      font-weight: normal;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      padding: 0 100px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      margin: 40px 0 60px 0;
      padding: 0;
      font-size: 18px;
      line-height: 30px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    letter-spacing: 0.3px;
    list-style: none;
    font-size: 20px;
    line-height: 28px;
    padding: 0 0 0 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      width: 80%;
      margin-top: 40px;
      font-size: 18px;
      line-height: 30px;
    }

    &.two-column {
      li {
        width: 50%;
        padding-right: 20px;

        &:nth-of-type(even) {
          @media screen and (max-width: ${({ theme }) =>
              theme.breakpoints.small}) {
            width: 100%;
            margin: 0;
          }
        }

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          width: 100%;
          margin: 0;
          padding-right: 0;
        }
      }
    }

    &.one-column {
      li {
        width: 100%;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          width: 100%;
          margin: 0;
        }
      }
    }

    li {
      position: relative;
      padding-left: 10px;

      &:before {
        position: absolute;
        left: -20px;
        content:"•"; 
        font-size: 12pt;
      }
    }
  }
`;

export default {
  ServiceCategories,
  Category,
};
