import styled from "styled-components";

const HighlightText = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightGrey2};

  .content {
    padding: 200px 0;
    max-width: 960px;
    margin: 0 auto;
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    color: ${({ theme }) => theme.colors.white};
    font-weight: normal;
    font-size: 45px;
    line-height: 60px;
    white-space: break-spaces;
    color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding: 65px 0;
      font-size: 30px;
      line-height: 40px;
    }
  }
`;

export default {
  HighlightText,
};
