import styled from "styled-components";
import { theme } from "../../../styles/theme";

const ImageSlider = styled.section`
  position: relative;
  padding-bottom: 100px;

  h2 {
    text-align: center;
    padding-bottom: 30px;
  }
  .swiper-scrollbar {
    display: none;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-prev {
    left: 0px;
  }
`;

export default {
  ImageSlider,
};
