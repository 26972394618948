import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { gsap, ScrollTrigger } from "gsap/all";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulHightlightText } from "../../../interfaces";

export const fragment = graphql`
  fragment HighlightText on ContentfulHighlightText {
    id
    internal {
      type
    }
    content {
      content
    }
  }
`;

interface HighlightTextProps extends ContentfulHightlightText {}

const HighlightText = ({ content: { content } }: HighlightTextProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const panelAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top center",
      },
    });
    panelAnimation.fromTo(
      ref.current,
      {
        opacity: 0,
        transform: "translateY(20px)",
      },
      {
        duration: 1,
        opacity: 1,
        transform: "translateY(0)",
      }
    );
  }, []);


  return (
    <S.HighlightText>
      <Container>
        <div className="content" ref={ref}>{content}</div>
      </Container>
    </S.HighlightText>
  );
};

export default HighlightText;
