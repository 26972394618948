import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "../../common/Slider";
import S from "./styles";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import { truncateString } from "../../../utils/helpers";

interface RecentArticlesProps {
  title?: string;
  carouselSettings?: any;
}

const RecentArticles = ({
  title,
  carouselSettings = {},
}: RecentArticlesProps) => {
  const { settings } = useContext(SiteSettingsContext);

  const { articles } = useStaticQuery(graphql`
    query RecentArticlesQuery {
      articles: allContentfulArticle(
        sort: { fields: date, order: DESC }
        limit: 8
      ) {
        edges {
          node {
            contentful_id
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            title
            date(formatString: "D MMM YYYY")
            slug
            author {
              name
            }
            category {
              title
              slug
            }
            excerpt {
              excerpt
            }
          }
        }
      }
    }
  `);

  if (!articles.edges) {
    return null;
  }

  return (
    <S.RecentArticles>
      <Slider
        title={title}
        carouselSettings={{ ...carouselSettings }}
        className="recent-articles"
        titleStyle={{ padding: "25px 0", textTransform: "uppercase" }}
      >
        {articles.edges
          .filter(a => a.node.slug != "dummy-article")
          .map(
            ({
              node: {
                title,
                image,
                slug,
                category,
                contentful_id,
                excerpt,
                author,
                date,
              },
            }) => (
              <S.Article
                cover
                direction="right"
                bg="white"
                to={`${settings.insightsPageSlug}/${category.slug}/${slug}`}
                key={contentful_id}
              >
                {image && <img src={image.fluid.src} />}
                <div className="type-category">{category.title} / ARTICLE</div>
                {title && <h3>{title}</h3>}
                {excerpt && (
                  <div className="excerpt">
                    {truncateString(excerpt.excerpt, 200)}
                  </div>
                )}
                <div className="meta">
                  <div className="author">{author.name}</div>
                  <div className="date">{date}</div>
                </div>
              </S.Article>
            )
          )}
      </Slider>
      {!articles.edges.length && (
        <S.NoResults>
          Sorry, there are no articles in this category.
        </S.NoResults>
      )}
    </S.RecentArticles>
  );
};

export default RecentArticles;
