import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const TeamGrid = styled.section`
  position: relative;

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    margin: 0 auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    }
  }
`;

const TeamMember = styled(AniLink)`
  position: relative;
  padding: 0 20px 100px 20px;
  width: 33.3333%;
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    padding: 0;
    margin-bottom: 60px;
  }

  .image {
    position: relative;
    overflow: hidden;

    .gatsby-image-wrapper {
      ${({ theme }) => theme.mixins.imageScale};
      height: 400px;

      img {
        object-fit: cover;
        max-width: 100%;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          margin: 0 -20px;
          max-width: none;
        }
      }
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-size: 35px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 0;
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-size: 22px;
    line-height: 36px;
    font-weight: normal;
    margin: 0;
  }
`;

export default {
  TeamGrid,
  TeamMember,
};
