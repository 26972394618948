import React, { useContext } from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulSlide, PathContext } from "../../../interfaces";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { getSlugByID, contentfulRichTextLinks } from "../../../utils/helpers";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";

export const fragment = graphql`
  fragment Slide on ContentfulSlides {
    id
    image {
      fluid {
        srcSet
      }
    }
    link {
      contentful_id
    }
    title
  }
`;
interface SlidesProps extends ContentfulSlide {
  pageMap: any;
}

const Slides = ({ image, link, title, pageMap }: SlidesProps) => {
  return (
    <S.Slide>
      {link && (
        <div style={{ marginBottom: "40px" }}>
          <AniLink
            className="link"
            to={getSlugByID({
              pageMap: pageMap,
              pageID: link.contentful_id,
            })}
            cover
            bg="white"
            direction="left"
          >
            <div className="gradient">
              <S.Image srcSet={image.fluid.srcSet} />
            </div>
            <h3>{title}</h3>
          </AniLink>
        </div>
      )}
    </S.Slide>
  );
};

export default Slides;
