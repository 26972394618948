import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulRichTextLinks, getSlugByID } from "../../../utils/helpers";
import Img from "gatsby-image";
import S from "./styles";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const ServiceCategory = ({ category }) => {
  const ref = useRef(null);

  useEffect(() => {
    const categoryAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top 75%",
      },
    });
    categoryAnimation.fromTo(
      ref.current.querySelector(".image"),
      {
        opacity: 0,
        y: 20,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
      }
    );
    categoryAnimation.fromTo(
      ref.current.querySelector(".title"),
      {
        opacity: 0,
        x: 20,
      },
      {
        duration: 0.5,
        opacity: 1,
        x: 0,
      }
    );
    if (category.content) {
      categoryAnimation.fromTo(
        ref.current.querySelector(".content"),
        {
          opacity: 0,
          y: 20,
        },
        {
          duration: 0.5,
          opacity: 1,
          y: 0,
        }
      );
    }
    if (category.services) {
      categoryAnimation.fromTo(
        ref.current.querySelector("ul"),
        {
          opacity: 0,
          y: 20,
        },
        {
          duration: 0.5,
          opacity: 1,
          y: 0,
        }
      );
    }
  }, []);

  return (
    <S.Category key={category.id} className="category" ref={ref}>
      <div className="image">
        <Img alt={category.image.title} fluid={category.image.fluid} />
      </div>
      <div className="title">
        <h3>{category.title}</h3>
      </div>
      
      {(category.content || category.services) && (
        <div className="content">
          {category.content && documentToReactComponents(category.content.json)}

          {category.services && (
            <ul
              className={`services-${category.id} 
                ${category.services.length >= 10 ? "two-column" : "one-column"}
              `}
            >
              {category.services.map(service => (
                <li className={`service-item-${category.id}`} key={service}>
                  {service}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </S.Category>
  );
};

export default ServiceCategory;
