import styled from "styled-components";

const BasicContent = styled.section`
  position: relative;
  padding-bottom: 100px;

  .content {
    position: relative;
    padding-top: 70px;
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 28px;
    max-width: 666px;
    margin: 0 auto;

    a {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      font-size: 18px;
      line-height: 32px;
      padding-bottom: 10px;
      text-decoration: none;
      border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey};
      letter-spacing: 1.3px;
    }
  }
`;

export default {
  BasicContent,
};
