import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { theme } from "../../../styles/theme";

const Slide = styled.section`
  position: relative;

  h3 {
    color: ${theme.colors.white};
    position: absolute;
    bottom: 3px;
    right: 30px;
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
  }
  a {
    text-decoration: none;
  }
  .gradient:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 55%;
    bottom: 0;
    background: -webkit-linear-gradient(transparent, #000) top repeat;
  }
`;

const Image = styled.img`
  position: relative;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  height: 400px;
  width: 100%;
  object-fit: cover;
`;

export default {
  Slide,
  Image,
};
