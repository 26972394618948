import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  getSlugByID,
} from "../../../utils/helpers";
import S from "./styles";
import { ContentfulBasicContent, PathContext } from "../../../interfaces";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const fragment = graphql`
  fragment BasicContent on ContentfulBasicContent {
    id
    internal {
      type
    }
    title
    content {
      json
    }
    backButtonLink {
      contentful_id
    }
    backButtonText
  }
`;

interface BasicContentProps extends ContentfulBasicContent {
  pathContext: PathContext;
}

const BasicContent = ({
  backButtonLink,
  backButtonText,
  title,
  content: { json },
  pathContext: { pageMap },
}: BasicContentProps) => {
  return (
    <S.BasicContent>
      <Container>
        <div className="content">
          {!title && backButtonLink && (
            <div style={{ marginBottom: "40px" }}>
              <AniLink
                className="back-link"
                to={getSlugByID({
                  pageMap: pageMap,
                  pageID: backButtonLink.contentful_id,
                })}
                cover
                bg="white"
                direction="left"
              >
                {backButtonText || "Back"}
              </AniLink>
            </div>
          )}
          {title && <h2>{title}</h2>}
          {documentToReactComponents(json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
              ...contentfulRichTextInlineImage,
            },
          })}
        </div>
      </Container>
    </S.BasicContent>
  );
};

export default BasicContent;
