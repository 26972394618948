import styled from "styled-components";

const BasicContentAlternate = styled.section`
  position: relative;
  padding-bottom: 100px;

  .content {
    position: relative;
    white-space: break-spaces;
    font-size: 20px;
    line-height: 28px;
    max-width: 830px;
    margin: 0 auto;

    h3 {
      font-size: 35px;
      line-height: 40px;
      margin: 10px 0;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      font-weight: normal;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 30px;
        line-height: 34px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 18px;
      font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
      font-weight: normal;
      letter-spacing: 3.6px;
      margin-top: 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
        font-size: 15px;
      }
    }

    a {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      font-size: 18px;
      line-height: 32px;
      padding-bottom: 10px;
      text-decoration: none;
      border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey};
      letter-spacing: 1.3px;
    }
  }
`;

export default {
  BasicContentAlternate,
};
