import { gsap, ScrollTrigger } from "gsap/all";

export const imageExpandAnimation = ref => {
  const position = ref.current.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  let toPosition;

  if (windowWidth <= 750) {
    toPosition = {
      left: 0,
      top: 0,
      right: 0,
      width: "100%",
      height: "60vh",
    };
  } else {
    toPosition = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
    };
  }

  gsap.fromTo(
    ref.current,
    {
      position: "fixed",
      left: position.left,
      top: position.top,
      bottom: position.bottom,
      right: position.right,
      width: position.width,
      height: position.height,
      zIndex: 10000,
      visibility: "visible",
    },
    {
      delay: 0,
      duration: 1,
      ...toPosition,
      position: "fixed",
      zIndex: 10000,
      ease: "expo.out",
      onComplete: () => {
        window.scrollTo(0, 0);
      },
    }
  );
};

export const desktopPanelAnimation = ref => {
  const panelAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: ref.current,
      start: "top 65%",
    },
  });
  panelAnimation.fromTo(
    ref.current.querySelector(".panel-content"),
    {
      opacity: 0,
      transform: "translateY(20px)",
    },
    {
      duration: 0.5,
      opacity: 1,
      transform: "translateY(0)",
    }
  );
  panelAnimation.fromTo(
    ref.current.querySelector(".original-image "),
    {
      opacity: 0,
      transform: "translateY(20px)",
    },
    {
      duration: 0.5,
      opacity: 1,
      transform: "translateY(0)",
    }
  );
};

export const mobilePanelAnimation = ref => {
  const panelAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: ref.current,
      start: "top center",
    },
  });
  panelAnimation.fromTo(
    ref.current.querySelector(".original-image "),
    {
      opacity: 0,
    },
    {
      duration: 0.5,
      opacity: 1,
    }
  );
  panelAnimation.fromTo(
    ref.current.querySelector(".panel-content"),
    {
      opacity: 0,
      transform: "translateY(20px)",
    },
    {
      duration: 0.5,
      opacity: 1,
      transform: "translateY(0)",
    }
  );
};
