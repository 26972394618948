// File to map React components to Contentful Content Models
import AlternatingPanels from "../components/content/AlternatingPanels";
import BasicContent from "../components/content/BasicContent";
import BasicContentAlternate from "../components/content/BasicContentAlternate";
import BasicTitleContent from "../components/content/BasicTitleContent";
import BoldText from "../components/content/BoldText";
import ContentSpacer from "../components/content/ContentSpacer";
import HighlightText from "../components/content/HighlightText";
import HomePageHeader from "../components/content/HomePageHeader";
import ImageSlider from "../components/content/ImageSlider";
import InsightsHomeContent from "../components/content/InsightsHomeContent";
import Locations from "../components/content/Locations";
import PageHeader from "../components/content/PageHeader";
import ServiceCategories from "../components/content/ServiceCategories";
import Slides from "../components/content/Slide";
import TeamGrid from "../components/content/TeamGrid";

export default {
  ContentfulAlternatingPanels: AlternatingPanels,
  ContentfulBasicContent: BasicContent,
  ContentfulBasicContentAlternate: BasicContentAlternate,
  ContentfulBasicTitleContent: BasicTitleContent,
  ContentfulBoldText: BoldText,
  ContentfulContentSpacer: ContentSpacer,
  ContentfulHighlightText: HighlightText,
  ContentfulHomeHeader: HomePageHeader,
  ContentfulHeader: PageHeader,
  ContentfulImageSlider: ImageSlider,
  ContentfulInsightsHomeContent: InsightsHomeContent,
  ContentfulLocationsContent: Locations,
  ContentfulServiceCategories: ServiceCategories,
  ContentfulTeamGrid: TeamGrid,
  // ContentfulSlides: Slides,
};
