import React, { useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { gsap, ScrollTrigger } from "gsap/all";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulBoldText } from "../../../interfaces";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

export const fragment = graphql`
  fragment BoldText on ContentfulBoldText {
    id
    internal {
      type
    }
    content {
      content
    }
  }
`;

interface BoldTextProps extends ContentfulBoldText {}

const BoldText = ({ content: { content } }: BoldTextProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const staggerAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top bottom",
      },
    });
    staggerAnimation.fromTo(
      ref.current,
      { opacity: 0, y: 20 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      }
    );
  }, []);

  return (
    <S.BoldText ref={ref}>
      <Container>
        <div className="content">{content}</div>
      </Container>
    </S.BoldText>
  );
};

export default BoldText;
