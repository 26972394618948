import styled from "styled-components";

const BasicTitleContent = styled.section`
  position: relative;
  padding-bottom: 100px;

  &:first-of-type {
    padding-top: 200px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-top: 100px;
    }
  }

  h2 {
    font-size: 35px;
    line-height: 40px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 30px;
      line-height: 34px;
    }

    &.section-title {
      border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey3};
      padding-bottom: 30px;
    }
  }

  .updated {
    font-size: 16px;
    line-height: 28px;
  }

  .content {
    padding-top: 70px;
    white-space: break-spaces;
    font-size: 20px;
    line-height: 28px;
    max-width: 800px;
    margin-left: auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 18px;
      line-height: 28px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-top: 40px;
    }

    h2 {
      padding-top: 60px;
      &:first-of-type {
        padding-top: 0;
      }
    }

    h3 {
      font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
      font-size: 28px;
      line-height: 38px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    h4 {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      font-size: 20px;
      line-height: 28px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
`;

export default {
  BasicTitleContent,
};
