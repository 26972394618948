import React from "react";
import { graphql } from "gatsby";
import S from "./styles";
import { ContentfulContentSpacer } from "../../../interfaces";

export const fragment = graphql`
  fragment ContentSpacer on ContentfulContentSpacer {
    id
    internal {
      type
    }
    size
    mobileSize
  }
`;

interface ContentSpacerProps extends ContentfulContentSpacer {}

const ContentSpacer = ({ size, mobileSize }: ContentSpacerProps) => {
  return <S.ContentSpacer size={size} mobileSize={mobileSize} />;
};

export default ContentSpacer;
