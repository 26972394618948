import styled from "styled-components";

const AlternatingPanels = styled.section`
  position: relative;

  .panel {
    &:nth-of-type(even) {
      .image {
        order: 2;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          order: 1;
        }
      }
      .panel-content {
        order: 1;
        padding: 80px 80px 80px 0px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          order: 2;
          padding: 40px 0 0 0;
        }
      }
    }
  }
`;

const Panel = styled.div`
  display: flex;
  padding-bottom: 150px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .image {
    position: relative;
    order: 1;
    flex: 3;
    min-height: 600px;
    max-height: 800px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      margin: 0 -20px;
      width: auto;
      height: 365px;
      min-height: auto;
    }

    .original-image {
      opacity: 0;
    }

    .gatsby-image-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-clone {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      visibility: hidden;

      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-color: white;
        z-index: 100000;
      }
    }
  }

  .panel-content {
    position: relative;
    display: flex;
    flex-direction: column;
    order: 2;
    padding: 80px 0 80px 80px;
    font-size: 20px;
    line-height: 30px;
    flex: 2;
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    color: ${({ theme }) => theme.colors.darkGrey};
    white-space: break-spaces;
    opacity: 0;

    .content {
      margin-top: 8px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      width: 100%;
      padding: 40px 0 0 0;
      font-size: 18px;
      line-height: 28px;
    }

    h2 {
      font-size: 35px;
      line-height: 40px;
      margin: 10px 0 0 0;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      font-weight: normal;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 30px;
        line-height: 34px;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 18px;
      font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
      font-weight: normal;
      letter-spacing: 3.6px;
      margin-top: 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
        font-size: 15px;
      }
    }

    a {
      position: relative;
      display: inline-block;
      font-size: 18px;
      line-height: 32px;
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      text-decoration: none;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.darkGrey};

      ${({ theme }) => theme.mixins.underlineLink};
      align-self: flex-start;
      margin-top: 20px;
    }
    p {
      margin-top: 25px;
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export default {
  AlternatingPanels,
  Panel,
};
