import styled from "styled-components";

const InsightsHomeContent = styled.section`
  position: relative;
  padding: 0 0 100px 0;

  .articles {
    display: flex;

    .container {
      padding: 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    .categories {
      padding-right: 20px;
      flex: 2;
    }

    .sliders {
      padding-left: 20px;
      flex: 5;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-left: 0;
      }
    }
  }

  .more-reading {
    display: flex;
    border-top: 1.5px solid ${({ theme }) => theme.colors.darkGrey};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    .popular-reads {
      padding-right: 20px;
      flex: 2;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-right: 0;
      }
    }

    .quick-read {
      padding-left: 20px;
      flex: 5;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-left: 0;
      }
    }
  }
`;

const Video = styled.video`
  position: relative;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  height: 800px;
  width: 100%;
  object-fit: cover;
`;

export default {
  InsightsHomeContent,
  Video,
};
