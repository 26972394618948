import React, { ReactNode } from "react";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import Slides from "../../content/Slide";
import S from "./styles";
import { ContentfulImageSlider, PathContext } from "../../../interfaces";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Container from "../../common/Container";

export const fragment = graphql`
  fragment ImageSlider on ContentfulImageSlider {
    id
    heading
    slides {
      image {
        title
        fluid {
          srcSet
        }
      }
      link {
        contentful_id
      }
      title
    }
    internal {
      type
    }
  }
`;

interface ImageSliderProps extends ContentfulImageSlider {
  pathContext: PathContext;
}

const ImageSlider = ({
  heading,
  slides,
  pathContext: { pageMap },
}: ImageSliderProps) => {
  if (!slides) return null;

  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  return (
    <S.ImageSlider>
      <Container>
        <h2>{heading}</h2>
        <Swiper
          {...params}
          spaceBetween={10}
          navigation
          breakpoints={{
            320: { slidesPerView: 1.5 },
            740: { slidesPerView: 3.5 },
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {slides.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <Slides {...slide} pageMap={pageMap} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </S.ImageSlider>
  );
};

export default ImageSlider;
