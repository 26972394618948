import React, { useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { gsap, ScrollTrigger } from "gsap/all";
import Img from "gatsby-image";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulHomePageHeader } from "../../../interfaces";

export const fragment = graphql`
  fragment HomeHeader on ContentfulHomeHeader {
    id
    internal {
      type
    }
    topPanelVideo {
      file {
        url
      }
    }
    topPanelImage {
      title
      fluid(quality: 80, maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    topPanelTitle
    bottomPanelTitleWhite {
      bottomPanelTitleWhite
    }
    bottomPanelTitleBlack {
      bottomPanelTitleBlack
    }
  }
`;

interface HomePageHeaderProps extends ContentfulHomePageHeader {}

const HomePageHeader = ({
  topPanelVideo,
  topPanelImage,
  topPanelTitle,
  bottomPanelTitleWhite,
  bottomPanelTitleBlack,
}: HomePageHeaderProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const panelAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
      },
    });
    panelAnimation.fromTo(
      ref.current.querySelector(".light"),
      {
        opacity: 0,
        transform: "translateY(20px)",
      },
      {
        duration: 1,
        opacity: 1,
        transform: "translateY(0)",
      }
    );
    panelAnimation.fromTo(
      ref.current.querySelector(".dark"),
      {
        opacity: 0,
        transform: "translateY(20px)",
      },
      {
        duration: 1,
        opacity: 1,
        transform: "translateY(0)",
      }
    );
  }, []);

  return (
    <S.HomePageHeader>
      {(topPanelVideo || topPanelImage) && (
        <div className="top">
          {topPanelVideo ? (
            <video
              autoPlay
              playsInline
              muted
              loop
              src={topPanelVideo.file.url}
            ></video>
          ) : (
            <Img
              alt={topPanelImage.title}
              fluid={topPanelImage.fluid}
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
          <Container>
            {topPanelTitle && <h2 className="HomeTitle">{topPanelTitle}</h2>}
            <div className="scroll-text">scroll</div>
          </Container>
        </div>
      )}
      {(bottomPanelTitleWhite || bottomPanelTitleBlack) && (
        <div className="bottom" ref={ref}>
          <Container>
            {bottomPanelTitleWhite && (
              <h1 className="light bottom-title">
                {bottomPanelTitleWhite.bottomPanelTitleWhite}
              </h1>
            )}
            {bottomPanelTitleBlack && (
              <h2 className="dark bottom-title">
                {bottomPanelTitleBlack.bottomPanelTitleBlack}
              </h2>
            )}
          </Container>
        </div>
      )}
    </S.HomePageHeader>
  );
};

export default HomePageHeader;
