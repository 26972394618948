import styled from "styled-components";

const BoldText = styled.section`
  position: relative;
  white-space: break-spaces;
  font-size: 28px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
  opacity: 0;

  &.in-view {
    animation: fadeInUp 2s ease-out;
    animation-fill-mode: forwards;
  }

  .content {
    padding: 100px 0;
    max-width: 850px;
    margin: 0 auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
      padding: 100px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding: 60px 0;
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

export default {
  BoldText,
};
