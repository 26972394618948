import styled from "styled-components";

const PageHeader = styled.section`
  position: relative;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightGrey2};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 60vh;
    padding: 20px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  h1 {
    text-align: center;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.white};
    font-size: 60px;
    line-height: 60px;
    z-index: 2;
    letter-spacing: 0.8px;
    animation: fadeInUp 0.5s 0.5s ease-out forwards;
    opacity: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  .scroll-text {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    bottom: 210px;
    right: 0;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-size: 22px;
    animation: fadeIn 0.5s 1.5s ease-out forwards;
    opacity: 0;

    &:before {
      position: absolute;
      top: 51%;
      left: -70px;
      overflow: hidden;
      width: 70px;
      height: 2px;
      margin-left: -20px;
      content: "\a0";
      background-color: white;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      display: none;
    }
  }
`;

export default {
  PageHeader,
};
