import styled from "styled-components";

const HomePageHeader = styled.section`
  position: relative;
  overflow: hidden;

  .top {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      height: 60vh;
    }

    img {
      position: absolute;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      animation: scaleDown 1s forwards ease-out;
    }

    video {
      position: absolute;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }

    .HomeTitle {
      flex: 1;
      z-index: 2;
      color: ${({ theme }) => theme.colors.white};
      font-size: 125px;
      font-weight: normal;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      margin: 0 0 60px 0;
      animation: fadeInUp 0.5s 1s ease-out forwards;
      opacity: 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 68px;
        line-height: 68px;
        margin-bottom: 30px;
      }
    }

    .scroll-text {
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      bottom: 210px;
      right: 0;
      color: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
      font-size: 22px;
      animation: fadeIn 0.5s 1.5s ease-out forwards;
      opacity: 0;

      &:before {
        position: absolute;
        top: 51%;
        left: -70px;
        overflow: hidden;
        width: 70px;
        height: 2px;
        margin-left: -20px;
        content: "\a0";
        background-color: white;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        display: none;
      }
    }
  }

  .bottom {
    position: relative;
    background-color: ${({ theme }) => theme.colors.lightGrey2};
    display: flex;
    align-items: center;
    padding: 200px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      height: auto;
      padding: 65px 0;
    }

    h1,
    h2 {
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      font-size: 45px;
      line-height: 60px;
      font-weight: normal;
      max-width: 925px;
      letter-spacing: -0.5px;
      padding-left: 110px;

      &.light {
        color: ${({ theme }) => theme.colors.white};
      }

      &.dark {
        color: ${({ theme }) => theme.colors.darkGrey};
      }

      @media screen and (max-width: 1100px) {
        padding-left: 0;
        margin: 0 auto;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 30px;
        line-height: 40px;
        max-width: 100%;
      }
    }
  }
`;

export default {
  HomePageHeader,
};
