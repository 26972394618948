import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const RecentArticles = styled.section`
  position: relative;
  border-bottom: 1.5px solid #3D4A4B;

  &:last-of-type {
    border-bottom: none;
  }
`;

const Article = styled(AniLink)`
  position: relative;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 25px 0 0;

  .type-category {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 28px;
  }

  img {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    margin: 0;
    padding: 15px 0;
    border-bottom: none;
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    margin: 0;
  }

  .excerpt {
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.mediumGrey2};
    padding-bottom: 20px;
  }

  .read-more {
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.mediumGrey2};
    padding-bottom: 5px;
  }

  .meta {
    .author,
    .date {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 23px;
      color: ${({ theme }) => theme.colors.mediumGrey2};
    }
  }
`;

const NoResults = styled.div`
  position: relative;
  text-align: center;
  font-size: 20px;
  margin-top: -50px;
`;

export default {
  RecentArticles,
  Article,
  NoResults,
};
