import React from "react";
import { graphql } from "gatsby";
import ServiceCategory from "./ServiceCategory";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulServiceCategories } from "../../../interfaces";

export const fragment = graphql`
  fragment ServiceCategories on ContentfulServiceCategories {
    id
    internal {
      type
    }
    categories {
      id
      title
      image {
        title
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      services
      content {
        json
      }
    }
  }
`;

interface ServiceCategoriesProps extends ContentfulServiceCategories {}

const ServiceCategories = ({ categories }: ServiceCategoriesProps) => {
  if (!categories) return null;

  return (
    <S.ServiceCategories>
      <Container>
        {categories.map(category => (
          <ServiceCategory key={category.id} category={category} />
        ))}
      </Container>
    </S.ServiceCategories>
  );
};

export default ServiceCategories;
