import styled from "styled-components";

const ContentSpacer = styled.div`
  position: relative;
  padding-top: ${({ size }) => (size ? `${size}px` : 0)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: ${({ mobileSize }) => (mobileSize ? `${mobileSize}px` : 0)};
  }
`;

export default {
  ContentSpacer,
};
