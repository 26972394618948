import React from "react";
import { graphql } from "gatsby";
import Panel from "./Panel";
import Container from "../../common/Container";
import S from "./styles";
import { ContentfulAlternatingPanels } from "../../../interfaces";

export const fragment = graphql`
  fragment AlternatingPanels on ContentfulAlternatingPanels {
    id
    internal {
      type
    }
    panels {
      id
      image {
        title
        fluid(quality: 80, maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      link {
        contentful_id
      }
      linkText
      animateImage
      title
      subtitle
      content {
        json
      }
    }
  }
`;

interface AlternatingPanelsProps extends ContentfulAlternatingPanels {
  pathContext: any;
  [rest: string]: any;
}

const AlternatingPanels = ({
  panels,
  pathContext: { pageMap },
}: AlternatingPanelsProps) => {
  if (!panels) return null;

  return (
    <S.AlternatingPanels>
      <Container>
        {panels.map(panel => (
          <Panel key={panel.id} panel={panel} pageMap={pageMap} />
        ))}
      </Container>
    </S.AlternatingPanels>
  );
};

export default AlternatingPanels;
