import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import contentfulComponentMap from "../../utils/contentfulComponentMap";
import { HeaderContext } from "../../context/HeaderContext";
import { SiteSettingsContext } from "../../context/SiteSettingsContext";

export const query = graphql`
  query PageQuery($contentful_id: String!) {
    pageData: contentfulPage(contentful_id: { eq: $contentful_id }) {
      title
      headerColor
      metadata {
        ...Metadata
      }
      content {
        ...AlternatingPanels
        ...BasicContent
        ...BasicContentAlternate
        ...BasicTitleContent
        ...BoldText
        ...ContentSpacer
        ...Header
        ...HighlightText
        ...HomeHeader
        ...ImageSlider
        ...InsightsHomeContent
        ...ServiceCategories
        ...TeamGrid
      }
    }
  }
`;

const Page = ({
  data,
  pathContext,
  transitionStatus,
  entry,
  exit,
  ...rest
}) => {
  const { setTheme, setPageTheme, setHeaderVisible } = useContext(
    HeaderContext
  );
  const { setSettings } = useContext(SiteSettingsContext);

  if (!data || !data.pageData) return null;

  const { content = [], metadata = {}, headerColor } = data.pageData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }

    if (headerColor) {
      setPageTheme(headerColor);
      setTheme(headerColor);
    }

    setHeaderVisible(true);
  }, [pathContext]);

  return (
    <Layout pathContext={pathContext} {...rest}>
      <Meta
        defaults={pathContext.settings.defaultPageMetadata}
        meta={{ ...metadata }}
      />
      {content &&
        content.map((component, index) => {
          const Component = contentfulComponentMap[component?.internal?.type];

          if (!Component) return null;

          return (
            <Component key={index} {...component} pathContext={pathContext} />
          );
        })}
    </Layout>
  );
};

export default Page;
