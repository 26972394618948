import React, { useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { gsap, ScrollTrigger } from "gsap/all";
import Img from "gatsby-image";
import Container from "../../common/Container";
import { getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulTeamGrid, PathContext } from "../../../interfaces";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

export const fragment = graphql`
  fragment TeamGrid on ContentfulTeamGrid {
    id
    internal {
      type
    }
    members {
      id
      name
      slug
      position
      credentials {
        credentials
      }
      image {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

interface TeamGridProps extends ContentfulTeamGrid {
  pathContext: PathContext;
}

const TeamGrid = ({
  members,
  pathContext: { pageMap, settings },
}: TeamGridProps) => {
  if (!members) return null;

  const ref = useRef(null);

  useEffect(() => {
    const staggerAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
      },
    });
    staggerAnimation.fromTo(
      ".team-member",
      { opacity: 0, y: 20 },
      {
        y: 0,
        opacity: 1,
        stagger: {
          // wrap advanced options in an object
          each: 0.5,
          from: "start",
          grid: "auto",
          axis: "y",
          ease: "power2.inOut",
          repeat: 0, // Repeats immediately, not waiting for the other staggered animations to finish
        },
      }
    );
  }, []);

  return (
    <S.TeamGrid ref={ref}>
      <Container>
        <div className="grid">
          {members.map((member, index) => (
            <S.TeamMember
              key={index}
              cover
              direction="left"
              bg="#D8E1DD"
              to={`${getSlugByID({
                pageMap,
                pageID: settings.teamPageID,
              })}/${member.slug}`}
              className="team-member"
            >
              {member.image && (
                <div className="image">
                  <Img fluid={member.image.fluid} alt={member.name} />
                </div>
              )}
              {member.name && <h3>{member.name}</h3>}
              {member.position && <h4>{member.position}</h4>}
              {member.credentials && <h4>{member.credentials.credentials}</h4>}
            </S.TeamMember>
          ))}
        </div>
      </Container>
    </S.TeamGrid>
  );
};

export default TeamGrid;
