import React from "react";
import { graphql } from "gatsby";
import Container from "../../common/Container";
import InsightsHeader from "../../content/InsightsHeader";
import InsightsCategories from "../../content/InsightsCategories";
import RecentArticles from "../RecentArticles";
import S from "./styles";
import { ContentfulInsightsHomeContent } from "../../../interfaces";

export const fragment = graphql`
  fragment InsightsHomeContent on ContentfulInsightsHomeContent {
    internal {
      type
    }
    quoteText {
      quoteText
    }
    quoteAuthor
    video {
      file {
        url
        contentType
      }
    }
  }
`;

interface InsightsHomeContentProps extends ContentfulInsightsHomeContent {}

const InsightsHomeContent = ({
  quoteText,
  quoteAuthor,
  video,
}: InsightsHomeContentProps) => {
  return (
    <S.InsightsHomeContent>
      <Container>
        <InsightsHeader
          quoteText={quoteText.quoteText}
          quoteAuthor={quoteAuthor}
        />
        {/* testing video */}
        {video && (
          <S.Video controls>
            <source src={video.file.url} type={video.file.contentType} />
          </S.Video>
        )}
        <div className="articles">
          <InsightsCategories />
          {RecentArticles && (
            <div className="sliders">
              <RecentArticles
                title="Recent"
                carouselSettings={{
                  breakPoints: [
                    { width: 1, itemsToShow: 1.2 },
                    { width: 750, itemsToShow: 2.5 },
                  ],
                }}
              />
            </div>
          )}
        </div>
      </Container>
    </S.InsightsHomeContent>
  );
};

export default InsightsHomeContent;
